<template>
  <div>
    <el-card class="plan">
      <div slot="header" class="flex flex-between flex-v-center">
        <el-page-header @back="goBack">
          <div slot="content" class="flex flex-v-center">
            <div>{{$t('制定计划')}}</div>
          </div>
        </el-page-header>
        <el-button round type="primary" @click="submit">({{ total_count() }}) {{$t('保存设置')}}</el-button>
      </div>

      <div v-if="form">
        <el-table
          v-for="(sort, index) in $store.state.sorts.filter((val) => { return val.type == ($store.state.mode == 'CCL' ? 1 : 0) })"
          :key="index" :show-header="index > 0 ? false : true" :data="sort.types" style="width: 100%">
          <el-table-column :label="$t('题型')" class="block">
            <template slot-scope="scope">
              <h4>
                {{ scope.row.title }}
              </h4>
            </template>
          </el-table-column>
          <el-table-column :label="$t('数量')" class="block">
            <template slot-scope="scope">
              <div class="">
                <el-input-number size="small" v-model="form[scope.row.tag].quantity" :min="0" :max="99" :label="$t('描述文字')">
                </el-input-number>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('标签')" class="block" width="360">
            <template slot-scope="scope">
              <div class="">
                <el-radio-group v-model="form[scope.row.tag].type">
                  <el-radio v-if="$store.state.mode == 'PTE'" label="is_forecast">{{$t('预测')}}</el-radio>
                  <el-radio v-if="$store.state.mode == 'PTE'" label="is_gmat">{{$t('机经')}}</el-radio>

                  <el-radio v-if="$store.state.mode == 'CCL'" label="is_mock">{{$t('模考')}}</el-radio>
                  <el-radio :label="null">{{$t('全部')}}</el-radio>
                </el-radio-group>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 1,
      form: null,
    };
  },

  created() {
    this.query();
  },

  methods: {
    total_count() {
      let count = 0;
      for (let key in this.form) {
        if ((this.$store.state.mode == "CCL" && this.$store.getters.getType(key).type == 1) || (this.$store.state.mode == "PTE" && this.$store.getters.getType(key).type == 0))
          if (this.form[key].quantity) {
            count += this.form[key].quantity;
          } else {
            this.form[key].type = "is_forecast"
          }
      }
      return count;
    },

    submit() {
      let items = [];
      for (let key in this.form) {
        items.push({ tag: key, quantity: this.form[key].quantity, type: this.form[key].type });
      }
      if (items.length > 0) {
        this.$http.post("/api/source/plan", { items: items }).then(() => {
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        })
      }
    },

    query() {
      this.$http.post("/api/source/plans").then((data) => {
        if (data) {
          this.form = data;
        }
      })
    },

    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.plan>>>.el-tabs__item.is-active,
.plan>>>.el-radio__input.is-checked+.el-radio__label,
.plan>>>.el-tabs__item:hover {
  color: #E6A23C;
}

.plan>>>.el-tabs__active-bar {
  background-color: #E6A23C;
}

.plan>>>.el-radio__input.is-checked .el-radio__inner {
  border-color: #E6A23C;
  background: #E6A23C;
}
</style>
